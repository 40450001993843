import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function PaymentFailed() {
    const navigate = useNavigate();

    const handleContinueShopping = () => {
        navigate('/shopping');
    };

    return (
        <div className='payment_failed_main'>
            <div>
                <div className='payment_failed_header'>
                <i class="fa-solid fa-circle-exclamation fa-2xl"></i><br /><br />
                    <h1 >Payment Failed</h1>
                </div>
                <p>
                    We're sorry, but your payment could not be processed. Please try again.
                </p>
                <Button
                    onClick={handleContinueShopping}
                >
                    back
                </Button>
            </div>
        </div>
    );
}